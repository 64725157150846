input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

form {
  margin-left: 5vh;
  padding: 20px;
}

.button-holder {
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--verylightgrey);
  outline: 0;
  font-size: 1.15rem;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
  padding-left: 0.3125em;
}
.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 1.15rem;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: var(--verylightgrey);
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, var(--yellow), #aeee19);
  border-image-slice: 1;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: var(--yellow);
  font-weight: 700;
}

.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

.options-wrapper {
  margin-top: 2vh;
}

.custom-select {
  min-width: 350px;
  position: relative;
}

select {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 0.3125em;
  background: transparent;
  border: 1px solid var(--verylightgrey);
  border-radius: 0.25rem;
  color: var(--verylightgrey);
  cursor: pointer;

  .option-color {
    color: var(--grey);
  }
}

.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  content: "";
  position: absolute;
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid var(--verylightgrey);
  top: 40%;
}

.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid var(--verylightgrey);
  top: 55%;
}

textarea {
  border-color: var(--verylightgrey);
  margin-top: 2vh;
  background: transparent;
  width: 100%;
  max-height: 500px;
  padding: 15px 15px 10px 5px;
  box-sizing: border-box;
  border-radius: 5px;
  resize: vertical;
  color: var(--white);
  font-size: 1.15rem;

  &::placeholder {
    color: var(--verylightgrey);
  }
}
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

@media screen and (max-width: 950px) {
  form {
    margin: 0;
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

.shake {
  animation: shake 0.5s;
}

.error-border {
  border-color: red;
}
