a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.button {
  position: relative;
  display: inline-block;
  padding: 15px 32px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  background-color: var(--yellow); /* Use your theme color */
  color: #111; /* Text color */
  border: 2px solid var(--yellow); /* Border color */
  border-radius: 30px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.button span {
  position: relative;
  z-index: 1;
}

.button:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 0;
  background-color: rgba(255, 255, 255, 0.5); /* Overlay color */
  transition: height 0.3s ease;
}

.button:hover:before {
  height: 100%;
}

.button svg {
  position: relative;
  top: 1px;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #111;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.button:hover svg {
  transform: translateX(0);
}

.button:active {
  transform: scale(0.95);
}
