.nav {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--grey);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
  height: 90px;
  border-radius: 4px;
  border-color: var(--verylightgrey);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  z-index: 2;
}

.nav img {
  width: 150px;
  margin-top: 10px;
  margin-right: 1vh;
  transition: 0.3s ease;
}

.nav img:hover {
  transform: scale(1.1);
}

#navbar {
  display: flex;
  align-items: center;
}

#navbar li {
  list-style-type: none;
  padding: 0 20px;
  position: relative;
}

#navbar li a {
  font-weight: 800;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--white);
  font-size: 20px;
}

.sticky-navbar {
  top: 0;
  left: 0;
  right: 0;
  animation: mymove 1s ease-in-out;
  z-index: 2;
}

.hover-underline::after {
  background: none repeat scroll 0 0 transparent;
  bottom: -4;
  content: "";
  display: block;
  height: 2.5px;
  left: 50%;
  position: absolute;
  background: var(--yellow);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.hover-underline:hover::after {
  width: 100%;
  left: 0;
}

#mobile {
  display: none;
}

#mobile i {
  color: var(--yellow);
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  top: 90px;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 9998;
  transition: 0.4s ease-in-out;
}

@media (min-width: 320px) {
  .nav {
    justify-content: space-between;
  }
}

@media (min-width: 480px) {
  .nav {
    justify-content: space-between;
  }
}

@media (min-width: 1281px) {
  .nav {
    justify-content: center;
  }
  #navbar {
    margin-left: 47vh;
  }
}

@media screen and (max-width: 768px) {
  #navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 74px;
    right: -800px;
    width: 65%;
    height: 100vh;
    background-color: var(--grey);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
    padding: 40px 0 0 10px;
    transition: 0.4s ease-in-out;
  }

  #navbar.active {
    right: 0px;
  }

  #navbar li {
    margin-bottom: 25px;
  }

  #mobile {
    display: block;
  }

  #mobile i {
    font-size: 24px;
    cursor: pointer;
  }
}
