.details-wrapper {
  height: 740px;
  text-align: center;
  background-color: var(--lightgrey);
  margin-right: 5vh;
  padding: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.contact {
  display: flex;
  flex-direction: column;
}

.phone {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-left: 1vh;
  }
}

.email {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-left: 1vh;
  }
}

.socials {
  width: 200px;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  padding-top: 30vh;
}

#facebook {
  transition: 0.3s ease-in-out;
}

#facebook:hover {
  color: #4267b2;
}

#linkedin {
  transition: 0.3s ease-in-out;
}

#linkedin:hover {
  color: #0077b5;
}

@media screen and (max-width: 950px) {
  .details-wrapper {
    align-self: center;
    width: 400px;
    margin: 0;
    height: 500px;
  }
  .socials {
    padding-top: 10vh;
  }
}
