.carousel-container {
  position: relative;
  margin: 0 auto;
  max-width: 100%;
}

.carousel-wrapper {
  margin: 5vh 1vh;
}

.carousel-slide {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.carousel-paper {
  width: 100%;
  position: relative;
  height: 300px;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}

.featured-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  p {
    padding-left: 1vh;
    font-size: 16px;
    color: var(--white);
  }
}
@media (max-width: 768px) {
  .carousel-slide {
    flex-direction: column; /* Stack items vertically */
  }

  .carousel-slide > .MuiGrid-item {
    width: 100%; /* Each item takes full width */
    padding: 0 10px; /* Optional: Adjust padding */
  }
}
