.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  align-items: center;
  padding-bottom: 50px;
  margin-bottom: 5vh;
}

.page-heading-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.form-container {
  width: 70%;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  height: 70vh;
  @media (max-width: 768px) {
    width: 90%;
  }
}

.contact-wrapper {
  display: flex;
  padding: 2vh;
}

@media screen and (max-width: 950px) {
  .contact-wrapper {
    flex-direction: column;
  }
}

.message-box {
  text-align: center;
  margin-top: 20px;
}

.message-box p {
  font-size: 1.2rem;
  margin-bottom: 10px;
}
