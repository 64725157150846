.hero-container {
  margin: 1.5vh;

  img {
    width: 100%;
    border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
  }
  h1 {
    color: var(--white);
    text-transform: none;
    letter-spacing: 0;
    font-size: 60px;
    line-height: 70px;
    font-weight: 400;
    margin-bottom: 24px;
  }
  h1::after {
    content: "";
    display: block;
    height: 5px;
    width: 60px;
    background: var(--yellow);
    margin: 10px 0 0 0;
  }
  p {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  @media screen and (max-width: 600px) {
    h1 {
      font-size: 40px;
    }
  }
}

.hero-container-ending {
  margin-top: 5vh;
  border: 0;
  height: 1px;
  background: var(--yellow);
  background-image: linear-gradient(var(--yellow));
}
