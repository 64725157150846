.footer-parent {
  background-color: var(--lightgrey);
}

.footer-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background-color: var(--lightgrey);
  color: #ffffff;
  max-width: 1300px;
  gap: 10px 20px;
  div {
    margin: 10px;

    p {
      font-weight: inherit;
      margin-bottom: 20px;
      line-height: 1.5em;
    }
  }
}

.footer-follow {
  ul {
    list-style: none;
    padding: 0;

    a {
      color: #ffffff;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}

.footer-50 {
  width: 50%;
}

.footer-25 {
  width: 25%;
}

@media screen and (max-width: 480px) {
  .footer-container {
    flex-direction: column;
    gap: 0;
  }
}
