:root {
  --grey: #2c2d34;
  --lightgrey: #393e46;
  --yellow: #efd510;
  --white: #fff;
  --verylightgrey: #bababa;
}

body {
  margin: 0;
  background-color: var(--grey);
  color: var(--white);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  body {
    height: 200vh;
  }
}

* {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

.main-content {
  max-width: 1300px;
  padding: 0;
  margin: 8vh auto 0;
}
