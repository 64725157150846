.card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .card-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .card-grid {
    grid-template-columns: 1fr;
  }
}
